<template lang="html">
  <FrsModuleRoot
    :module="module"
    :module-path="['fieldRecordSystem', 'cultivation']"
    :translation-namespaces="['cultivation']"
    :setup="loadPrerequisiteData"
    persistent
  >
    <template #left>
      <CultivationNavigation />
    </template>

    <FrsRouterBackLink target="orgDashboard">
      <template #text>
        <IxRes>cultivation.buttons.backToDashboard</IxRes>
      </template>
    </FrsRouterBackLink>

    <router-view />

    <FrsLoadingIndicator
      :requests="['orgUnit.cultivationsOverview.loadCultivations',
                  'orgUnit.cultivationsOverview.loadDetails',
                  'orgUnit.cultivationsOverview.loadActions',
                  'orgUnit.cultivationsOverview.delete',
                  'data.machines', 'data.persons']"
    />
  </FrsModuleRoot>
</template>

<script>
import {mapActions} from 'vuex'

import FrsRouterBackLink from '@frs/components/base/FrsRouterBackLink'
import FrsModuleRoot from '@frs/components/base/FrsModuleRoot'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

import CultivationNavigation from './overview/CultivationNavigation'

export default {
  components: {
    FrsLoadingIndicator,
    CultivationNavigation,
    FrsModuleRoot,
    FrsRouterBackLink
  },
  computed: {
    module () {
      return () => import('@store/modules/field-record-system/cultivation-overview')
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/cultivationOverview', [
      'loadPrerequisiteData'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
