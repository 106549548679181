<template>
  <div class="subtitle-container">
    <span class="type-wrapper" :title="$i18n.translate(`Areas.FieldRecordSystem.SR_FieldRecordSystem.CultivationType_${cultivation.type}`)">
      {{ cultivation.typeShortcut }}
    </span>
    <div class="info-wrapper">
      <IxRes :context="{start: $i18n.format(cultivation.dateTimeRange.start, 'date'), end: $i18n.format(cultivation.dateTimeRange.end, 'date')}">
        cultivation.card.dateRange
      </IxRes>
      <span v-if="fields.length" class="field-name-wrapper" :title="cultivation.fieldNames">
        {{ fieldNames }}
      </span>
    </div>
  </div>
</template>

<script>
import {uniq} from 'lodash'
import {mapUiFlags} from '@frs/helpers/ui'

export default {
  props: {
    cultivation: {
      required: true,
      type: Object
    },
    fields: Array
  },
  computed: {
    ...mapUiFlags([
      'dashboard.showFieldCodes'
    ]),
    fieldNames () {
      if (this.showFieldCodes) {
        const codes = uniq(this.fields.map(field => field.properties.code).filter(code => code))
        return codes.length ? `- ${codes.join(', ')}` : ''
      } else {
        const names = uniq(this.fields.map(field => field.properties.name))
        return names.length ? `- ${names.join(', ')}` : ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.subtitle-container {
  display: flex;
  width: 100%;

  .type-wrapper {
    border: 1px solid darkgray;
    color: darkgray;
    border-radius: 5px;
    margin-right: 4px;
    padding: 0 4px;
    display: inline-block;
  }

  .info-wrapper {
    display: contents;

    .field-name-wrapper {
      font-style: italic;
      padding-right: 2px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &:hover {
    cursor: default;
  }
}
</style>
