<template>
  <div class="avatar-container">
    <div class="cultivation-avatar">
      <svg
        viewBox="-52 -52 104 104"
        width="100%" height="100%"
      >
        <polygon
          :points="fieldPolygon"
          stroke="black"
          stroke-width="2"
          fill="rgba(1, 0, 0, 0)"
        />
        <polygon
          :points="cultivationPolygon"
          :fill="cultivation.properties.color"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import bbox from '@turf/bbox'

import {toSvgPolygons} from '@helpers/geojson'

export default {
  props: {
    cultivation: {
      type: Object,
      required: true
    },
    field: {
      type: Object,
      required: true
    }
  },
  computed: {
    bbox () {
      const collection = {type: 'FeatureCollection',
        features: [
          this.cultivation
        ]
      }
      if (this.field) {
        collection.features.push(this.field)
      }
      return bbox(collection)
    },
    cultivationPolygon () {
      return toSvgPolygons(this.cultivation.geometry, this.bbox)
    },
    fieldPolygon () {
      if (!this.field) return
      return toSvgPolygons(this.field.geometry, this.bbox) || null
    },
    dateTimeRange () {
      return {
        start: moment(this.cultivation.properties.dateTimeRange.start),
        end: moment(this.cultivation.properties.dateTimeRange.end)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-container {
  width: 40px;
  height: 40px;
}
</style>
