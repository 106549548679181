<template lang="html">
  <div class="harvest-year-selector">
    <span class="harvest-year-label">
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.HarvestYear</IxRes>
    </span>
    <div class="harvest-year-controls" :class="{visible: !!harvestYear, disabled}">
      <a
        class="previous" href="#"
        :title="backTitle"
        @click.prevent="onClick(-1)"
      >
        <i class="fa fa-angle-left" /> {{ harvestYear - 1 }}
      </a>
      <span class="current-harvest-year">{{ harvestYear }}</span>
      <a
        class="next" href="#"
        :title="forwardTitle"
        @click.prevent="onClick(+1)"
      >
        {{ harvestYear + 1 }} <i class="fa fa-angle-right" />
      </a>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

import {mapResources} from '@helpers/vuex'

export default {
  props: {
    disabled: Boolean
  },
  computed: {
    ...mapResources([
      '@frs.SR_FieldRecordSystem'
    ]),
    ...mapState('fieldRecordSystem', {
      harvestYear: state => state.userSettings.harvestYear
    }),
    backTitle () {
      return this.disabled
        ? this.SR_FieldRecordSystem.CannotChangeHarvestYearHint
        : this.SR_FieldRecordSystem.SelectLastCropYear
    },
    forwardTitle () {
      return this.disabled
        ? this.SR_FieldRecordSystem.CannotChangeHarvestYearHint
        : this.SR_FieldRecordSystem.SelectNextCropYear
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem', [
      'setHarvestYear'
    ]),
    onClick (delta) {
      if (!this.disabled) {
        this.$emit('changeHarvestYear')
        this.setHarvestYear(this.harvestYear + delta)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.harvest-year-selector {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.harvest-year-label {
  font-size: 1.3em;
  line-height: 0.5em;
  font-weight: bold;
  text-transform: uppercase;
  color: lightgray;
}

.harvest-year-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4em;
  line-height: 0.2em;

  transition: opacity 0.3s;
  &:not(.visible) {
    opacity: 0;
  }

  a {
    transition: color 0.2s;
  }
}

.current-harvest-year {
  font-size: 2.2em;
  font-weight: bold;
  margin: 0px 10px;
  line-height: 1.1em;
}

.disabled a {
  cursor: help;
  color: lightgray;

  &:hover, &:focus {
    color: lightgray;
    text-decoration: none;
  }
}
</style>
