<script>
import {get, groupBy, isEqual} from 'lodash'

export default {
  props: {
    rules: {
      type: Array,
      required: true
    },
    data: {
      type: Array,
      required: true
    }
  },
  computed: {
    rulesAccepted () {
      return this.rules.every(rule => rule.hasOwnProperty('property') && rule.hasOwnProperty('filter') && rule.hasOwnProperty('operation'))
    },
    preparedRuleFunctions () {
      return this.createFilterConditions(this.rules)
    },
    acceptedData () {
      const acceptedData = this.data.filter(data => this.preparedRuleFunctions.every(x => x.some(rule => rule(data))))

      this.$emit('accepted-data', acceptedData)
      return acceptedData
    },
    acceptedDataIds () {
      const acceptedDataIds = this.acceptedData.map(data => data.id)

      this.$emit('accepted-data-ids', acceptedDataIds)
      return acceptedDataIds
    },
    notAcceptedDataIds () {
      const notAcceptedDataIds = this.data.filter(data => !(this.preparedRuleFunctions.every(x => x.some(rule => rule(data)))))
          .map(data => data.id)

      this.$emit('not-accepted-data-ids', notAcceptedDataIds)
      return notAcceptedDataIds
    }
  },
  methods: {
    createFilterConditions (rules) {
      const groupedRules = groupBy(rules, 'property')

      return Object.keys(groupedRules)
          .map(key => groupedRules[key].map(rule => this.createRuleFunction(rule)).filter(x => x))
    },
    createRuleFunction (rule) {
      if (!this.checkRule(rule)) return null

      let values = null
      switch (rule.operation) {
      case 'eq':
        return (o) => { return get(o, rule.property).toString().toLowerCase() === rule.filter.toString().toLowerCase() }
      case 'neq':
        return (o) => { return get(o, rule.property).toString().toLowerCase() !== rule.filter.toString().toLowerCase() }
      case 'gt':
        return (o) => { return Number(get(o, rule.property)) > Number(rule.filter) }
      case 'lt':
        return (o) => { return Number(get(o, rule.property)) < Number(rule.filter) }
      case 'gte':
        return (o) => { return Number(get(o, rule.property)) >= Number(rule.filter) }
      case 'lte':
        return (o) => { return Number(get(o, rule.property)) <= Number(rule.filter) }
      case 'ir':
        values = rule.filter.toString().split('-')
        return (o) => { return Number(get(o, rule.property)) >= Number(values[0]) && Number(get(o, rule.property)) <= Number(values[1]) }
      case 'or':
        values = rule.filter.toString().split('-')
        return (o) => { return !(Number(get(o, rule.property)) >= Number(values[0]) && Number(get(o, rule.property)) <= Number(values[1])) }
      case 'inc':
        return (o) => { return get(o, rule.property).toString().toLowerCase().includes(rule.filter.toString().toLowerCase()) }
      default:
        return (o) => { return get(o, rule.property).toString().toLowerCase() === rule.filter.toString().toLowerCase() }
      }
    },
    checkRule (rule) {
      return rule.hasOwnProperty('property') && rule.hasOwnProperty('filter') && rule.hasOwnProperty('operation')
    },
    removeRule (rule) {
      this.$emit('input', this.rules.filter(r => !isEqual(r, rule)))
    }
  },
  render () {
    return this.$scopedSlots.default({
      rulesAccepted: this.rulesAccepted,
      acceptedData: this.acceptedData,
      acceptedDataIds: this.acceptedDataIds,
      notAcceptedDataIds: this.notAcceptedDataIds,
      removeRule: this.removeRule
    })
  }
}
</script>
