<template>
  <div class="filter-crop-usage">
    <a class="reset-button" href="#" @click.prevent="deselectFilterType('label')">
      <IxRes> cultivation.filter.cultivation.reset</IxRes>
    </a>
    <BsCheckboxList
      v-model="checkboxOptionsModel"
      :options="uniqueCultivationLabels"
      name="checkboxes"
      class="checkbox-list"
    >
      <template #default="{option}">
        {{ option }}
      </template>
    </BsCheckboxList>
  </div>
</template>

<script>
import {mapState, mapMutations, mapActions, mapGetters} from 'vuex'
import {uniqBy, sortBy, difference, head} from 'lodash'

import BsCheckboxList from '@components/bootstrap/BsCheckboxList'

export default {
  components: {
    BsCheckboxList
  },
  computed: {
    ...mapState('fieldRecordSystem/cultivationOverview', {
      filterRules: state => state.filterRules
    }),
    ...mapGetters('fieldRecordSystem/cultivationOverview', [
      'cultivationData'
    ]),
    labelsFilteredFromRules () {
      return this.filterRules.filter(rule => rule.property === 'label').map(rule => rule.filter)
    },
    uniqueCultivationLabels () {
      return sortBy(uniqBy(this.cultivationData, 'label'), 'label')
          .map(cultivation => cultivation.label)
    },
    checkboxOptionsModel: {
      get () {
        return this.labelsFilteredFromRules
      },
      set (labels) {
        const labelDifference = this.labelsFilteredFromRules.length > labels.length
          ? difference(this.labelsFilteredFromRules, labels)
          : difference(labels, this.labelsFilteredFromRules)
        this.acceptAndToggleFilterRule({
          property: 'label',
          filter: head(labelDifference),
          operation: 'eq'
        })
      }
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/cultivationOverview', [
      'acceptAndToggleFilterRule'
    ]),
    ...mapMutations('fieldRecordSystem/cultivationOverview', [
      'deselectFilterType'
    ])
  }
}
</script>

<style lang="scss" scoped>
.filter-crop-usage {
  display: flex;
  flex-direction: column;

  .checkbox-list {
    overflow: auto;
    max-height: 400px;
  }

  .reset-button {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
