import WKT from 'ol/format/WKT'
import GeoJSON from 'ol/format/GeoJSON'
import proj4 from 'proj4'
import bbox from '@turf/bbox'

import {flatten} from '@helpers/reducers'

const reproject = point => proj4('EPSG:4326', 'GOOGLE', point)

const wktFormat = new WKT()
const geoJsonFormat = new GeoJSON()

export function fromWkt (wkt) {
  const geometry = wktFormat.readGeometry(wkt)
  const geoJsonString = geoJsonFormat.writeGeometry(geometry)
  return JSON.parse(geoJsonString)
}

// normalized to [-50, 50]
export function toSvgPolygons (geojson, externalBbox) {
  if (!geojson) return

  if (geojson.type === 'FeatureCollection') {
    return geojson.features
      .map(feature => toSvgPolygons(feature, externalBbox))
      .reduce(flatten, [])
  }

  if (geojson.type === 'Feature') {
    geojson = geojson.geometry
  }

  const multiPolygon = geojson.type === 'Polygon'
    ? [geojson.coordinates]
    : geojson.coordinates

  if (multiPolygon.length === 0) return

  const [minX, minY, maxX, maxY] = externalBbox || bbox(geojson)
  const min = reproject([minX, minY])
  const max = reproject([maxX, maxY])

  const dimensions = [max[0] - min[0], max[1] - min[1]]
  const center = min.map((x, i) => x + 0.5 * dimensions[i])

  const longestDimension = Math.max(...dimensions)

  const normalize = point => point.map((x, i) => 50 * (x - center[i]) * 2 / longestDimension)
  const flip = ([x, y]) => [x, -y]
  const serialize = point => point.join(',')

  const serializeOuterRing = polygon => polygon[0]
    .map(reproject)
    .map(normalize)
    .map(flip)
    .map(serialize)
    .join(' ')

  return multiPolygon.map(serializeOuterRing)
}
