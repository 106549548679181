<template>
  <div @click="$emit('select')">
    <ListCard :selected="selected" show-avatar>
      <template #avatar>
        <CultivationCardAvatar :field="fieldFeature" :cultivation="cultivation" />
      </template>
      <template #title>
        <span :title="title">{{ title }}</span>
      </template>
      <template #subtitle>
        <CultivationCardSubtitle :cultivation="cultivation.properties" :fields="fields" />
      </template>
    </ListCard>
  </div>
</template>

<script>
import {first} from 'lodash'
import ListCard from '@components/card/ListCard'

import CultivationCardSubtitle from './CultivationCardSubtitle'
import CultivationCardAvatar from './CultivationCardAvatar'

export default {
  components: {
    CultivationCardSubtitle,
    CultivationCardAvatar,
    ListCard
  },
  props: {
    cultivation: {
      type: Object,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    selected: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    cultivationArea () {
      return this.$i18n.format(this.cultivation.properties.area, 'area')
    },
    fieldFeature () {
      return first(this.fields)
    },
    title () {
      return `${this.cultivation.properties.label} (${this.cultivationArea})`
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
