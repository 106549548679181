<template>
  <div class="cultivation-list-container">
    <HarvestYearSelector @changeHarvestYear="harvestYearChange" />

    <ListCard>
      <template #title>
        <span class="org-name" :title="orgUnitName">{{ orgUnitName }}</span>
      </template>
      <template #addon>
        <IxButton
          class="fancy-button" icon="plus-square-o"
          rounded large
          @click="startPlanning"
        >
          <IxRes>cultivation.labels.addCultivation</IxRes>
        </IxButton>
      </template>
      <template #bottom>
        <SimpleSwitch v-model="showFieldCodes">
          <IxRes>cultivation.labels.showFieldCode</IxRes>
        </SimpleSwitch>
      </template>
    </ListCard>

    <i class="fa fa-angle-down fa-2x" />
    <FilterOverview />
    <RecycleScroller
      v-slot="{item}" :items="sortedVisibleCultivationData"
      class="scroller" :item-size="66"
      key-field="id"
    >
      <CultivationCard
        :cultivation="cultivationFeatureLookup[item.id]"
        :selected="selected.includes(item.id)"
        :fields="item.fieldIds.map(id => fieldFeatureLookup[id])"
        class="card-item"
        @select="toggleCultivation(item.id)"
      />
    </RecycleScroller>

    <IxVectorLayer
      :features="visibleCultivationFeatures" layer-id="cultivations"
      :vector-style="styles.cultivation"
      :z-index="1"
      auto-focus
    />
    <!-- only the fields for selected cultivations are diplayed -->
    <IxVectorLayer
      :features="selectedFieldFeatures" layer-id="fields"
      :vector-style="styles.field"
      :z-index="2"
    />

    <SelectInteraction
      :value="selected"
      :features="visibleCultivationFeatures"
      :layer-filter="layer => layer.get('id') === 'cultivations'"
      :hover-style="styles.hover"
      :selection-style="styles.selected"
      multiple
      @toggle="toggleFeatureByMapInteraction"
    />
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'
import {sortBy, uniq, isEqual} from 'lodash'
import {mapUiFlags} from '@frs/helpers/ui'
import {
  cultivationWithCode,
  cultivationWithoutText,
  cultivationFieldBorder,
  actionCultivationSelectionHover,
  actionCultivationSelectionSelected
} from '@frs/map-styles'

import CultivationCard from './CultivationCard'
import FilterOverview from './filter/FilterOverview'

import HarvestYearSelector from '@frs/components/navigation/HarvestYearSelector'

import IxVectorLayer from '@components/map/IxVectorLayer'
import ListCard from '@components/card/ListCard'
import IxButton from '@components/IxButton'
import SimpleSwitch from '@components/forms/SimpleSwitch'

import DisableDefaultMapLayersMixin from '@frs/mixins/DisableDefaultMapLayersMixin'
import DisableDefaultMapInteractionsMixin from '@frs/mixins/DisableDefaultMapInteractionsMixin'
import SelectInteraction from '@components/map/interactions/SelectInteraction'

export default {
  components: {
    IxButton,
    ListCard,
    CultivationCard,
    IxVectorLayer,
    HarvestYearSelector,
    SimpleSwitch,
    FilterOverview,
    SelectInteraction
  },
  mixins: [
    DisableDefaultMapInteractionsMixin,
    DisableDefaultMapLayersMixin
  ],
  computed: {
    ...mapUiFlags([
      'dashboard.showFieldCodes'
    ]),
    ...mapGetters('fieldRecordSystem', [
      'orgUnitId',
      'harvestYear'
    ]),
    ...mapState('fieldRecordSystem/cultivationOverview', {
      orgUnitName: state => state.orgUnitName,
      selected: state => state.selected
    }),
    ...mapGetters('fieldRecordSystem/cultivationOverview', [
      'visibleCultivationFeatures',
      'visibleCultivationData',
      'cultivationData',
      'cultivationFeatureLookup',
      'selectedFieldFeatures',
      'fieldFeatureLookup'
    ]),
    sortedVisibleCultivationData () {
      const data = [...this.visibleCultivationData]
      data.forEach(data => {
        const fieldsForCultivation = data.fieldIds.map(id => this.fieldFeatureLookup[id]).map(field => ({
          name: field.properties.name,
          code: field.properties.code
        }))

        data.fieldNames = uniq(fieldsForCultivation.map(field => field.name)).join(', ')
        data.fieldCodes = uniq(fieldsForCultivation.map(field => field.code)).join(', ')
      })
      return this.showFieldCodes ? sortBy(data, 'fieldCodes') : sortBy(data, 'fieldNames')
    },
    styles () {
      return {
        cultivation: this.showFieldCodes ? cultivationWithCode : cultivationWithoutText,
        field: cultivationFieldBorder,
        hover: actionCultivationSelectionHover,
        selected: actionCultivationSelectionSelected
      }
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/cultivationOverview', [
      'toggleCultivation'
    ]),
    ...mapActions('fieldRecordSystem', [
      'cultivationPlanningOrgUnit'
    ]),
    ...mapMutations('fieldRecordSystem/cultivationOverview', [
      'reset',
      'clearSelected'
    ]),
    startPlanning () {
      this.$router.push({name: 'orgDashboard'})
      this.cultivationPlanningOrgUnit({cultivationId: null})
    },
    harvestYearChange () {
      if (this.$route.name !== 'cultivations') {
        this.$router.push({name: 'cultivations'})
      }
      this.reset()
    },
    toggleFeatureByMapInteraction (feature) {
      const id = feature.properties.id
      this.toggleCultivation(id)
    },
    syncStoreFromRoute () {
      switch (this.$route.name) {
      case 'cultivations':
        if (this.$route.query.selectedFieldId) {
          const cultivationsForField = this.cultivationData.filter(x => x.fieldIds.includes(this.$route.query.selectedFieldId))

          // single selection
          if (cultivationsForField.length === 1) {
            // store update is handled in 'details' case
            this.$router.push({name: 'details', params: {orgUnitId: this.orgUnitId, year: this.harvestYear, cultivationId: cultivationsForField[0].id}})
          } else {
            // multi selection
            this.$router.replace({query: {}})

            const cultivationIds = cultivationsForField.map(x => x.id)
            const cultivationsAlreadySelected = isEqual(this.selected, cultivationIds)
            if (cultivationsAlreadySelected) return

            this.clearSelected()
            cultivationsForField.forEach(cultivation => {
              this.toggleCultivation(cultivation.id)
            })
          }
        }
        break
      case 'details':
        const cultivationAlreadySelected = this.selected.length === 1 && this.selected[0] === this.$route.params.cultivationId
        if (cultivationAlreadySelected) return

        this.clearSelected()
        this.toggleCultivation(this.$route.params.cultivationId)
        break
      default:
        throw new Error('unsupported route')
      }
    }
  },
  watch: {
    // internal state changed
    selected (newIds, oldIds) {
      if (newIds.length === 1 && oldIds.length !== 1 && this.$route.name !== 'details') {
        this.$router.push({name: 'details', params: {cultivationId: newIds[0]}})
      } else if (newIds.length !== 1 && oldIds.length === 1 && this.$route.name !== 'cultivations') {
        this.$router.push({name: 'cultivations'})
      }
    },
    $route: 'syncStoreFromRoute'
  },
  created () {
    this.syncStoreFromRoute()
  },
  beforeDestroy () {
    this.reset()
  }
}
</script>

<style lang="scss" scoped>
.cultivation-list-container {
  position:relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 8px;

  .fa {
    align-self: center;
    display: flex;
  }

  .scroller {
    flex: 1;
    margin-left: 16px;
  }

  .org-name:hover {
    cursor: default;
  }
  .fancy-button {
    flex: 1;

    &:active {
      outline: none;
    }
  }
}

.card-item {
  height: 66px;
}
</style>
