<template>
  <div>
    <div class="toggle-button-container">
      <ToggleButton
        v-for="(button, index) in toggleButtons" :key="index"
        :toggle-value="selectedButtons"
        class="fancy-button"
        :disabled="!availableTypes.includes(button.value)"
        :value="button.value"
        :title="$i18n.translate(`Areas.FieldRecordSystem.SR_FieldRecordSystem.CultivationType_${button.value}`)"
        @input="createRule(button)"
      >
        {{ button.title }}
      </ToggleButton>
    </div>
    <div class="reset-button">
      <a href="#" @click.prevent="deselectFilterType('type')">
        <IxRes> cultivation.filter.cultivation.reset</IxRes>
      </a>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations, mapGetters} from 'vuex'
import {uniq} from 'lodash'

import ToggleButton from '@components/ToggleButton'

export default {
  components: {
    ToggleButton
  },
  computed: {
    ...mapState('fieldRecordSystem/cultivationOverview', {
      filterRules: state => state.filterRules
    }),
    ...mapGetters('fieldRecordSystem/cultivationOverview', [
      'cultivationData'
    ]),
    toggleButtons () {
      return ['main', 'secondary', 'catch', 'eco'].map(type => ({
        value: type,
        title: this.$i18n.translate(`Areas.FieldRecordSystem.SR_FieldRecordSystem.CultivationType_${type}_shortcut`)
      }))
    },
    availableTypes () {
      return uniq(this.cultivationData.map(cultivation => cultivation.type))
    },
    selectedButtons () {
      return this.filterRules.map(rule => rule.filter)
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/cultivationOverview', [
      'deselectFilterType'
    ]),
    ...mapActions('fieldRecordSystem/cultivationOverview', [
      'acceptAndToggleFilterRule'
    ]),
    createRule (button) {
      const rule = {
        property: 'type',
        filter: button.value,
        operation: 'eq'
      }
      this.acceptAndToggleFilterRule(rule)
    }
  }
}
</script>

<style lang="scss" scoped>
.toggle-button-container {
  display: flex;

  :not(:last-child) {
    margin-right: 4px;
  }
}

.reset-button {
  display: flex;
  justify-content: flex-end;
  padding: 3px 0 0 0;
}

.fancy-button {
  border-radius: 6px;
  flex: 1;

  &:active {
    outline: none;
    border: none;
  }
}
</style>
