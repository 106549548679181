<template>
  <RenderlessFilterComponent
    :data="cultivationData" :rules="filterRules"
    @accepted-data="setVisibleCultivations"
  >
    <div slot-scope="{acceptedDataIds}" class="renderless">
      <CollapsibleSection v-model="cultivationfilterCollapsed">
        <template #header>
          <IxRes>cultivation.filter.cultivation.title</IxRes>
          <div v-if="!cultivationfilterCollapsed" class="collapsible-hint">
            <IxRes :context="{count: typeRuleCount}"> cultivation.filter.cultivation.title.info</IxRes>
          </div>
        </template>
        <FilterToggleButtons />
      </CollapsibleSection>
      <CollapsibleSection v-model="cropUsagefilterCollapsed">
        <template #header>
          <IxRes>cultivation.filter.cropUsage.title</IxRes>
          <div v-if="!cropUsagefilterCollapsed" class="collapsible-hint">
            <IxRes :context="{count: labelRuleCount}"> cultivation.filter.cropUsage.title.info</IxRes>
          </div>
        </template>
        <FilterCropUsage />
      </CollapsibleSection>
      <div>
        <IxRes :context="{selected: selected.length, count: cultivationData.length, visible: visible.length}">
          cultivation.card.info.selected.cultivations
        </IxRes>
      </div>
      <div>
        <a class="link" href="#" @click.prevent="setSelected(acceptedDataIds)">
          <IxRes> cultivation.card.info.selected.cultivations.all</IxRes>
        </a>
        <a class="link" href="#" @click.prevent="clearSelected">
          <IxRes> cultivation.card.info.selected.cultivations.none</IxRes>
        </a>
      </div>
      <HelpBox v-if="cultivations.length > 0 && invisibleCount(acceptedDataIds) > 0" class="help-box" type="danger">
        <IxRes :context="{count: invisibleCount(acceptedDataIds)}">
          cultivation.card.info.hidden.title
        </IxRes>
        <a href="#" @click.prevent="setSelected(selected.filter(id => acceptedDataIds.includes(id)))">
          <IxRes> cultivation.card.info.hidden.subtitle</IxRes>
        </a>
      </HelpBox>
      <HelpBox v-if="cultivations.length > 0 && visible.length === 0" class="cultivation-card" type="danger">
        <IxRes :context="{count: invisibleCount(acceptedDataIds)}">
          cultivation.card.info.empty.title
        </IxRes>
        <a href="#" @click.prevent="clearFilter">
          <IxRes> cultivation.card.info.empty.subtitle</IxRes>
        </a>
      </HelpBox>
      <HelpBox v-if="cultivations.length == 0" class="cultivation-card" type="info">
        <IxRes>cultivation.card.info.no-cultivations</IxRes>
      </HelpBox>
    </div>
  </RenderlessFilterComponent>
</template>

<script>
import {mapState, mapGetters, mapMutations} from 'vuex'
import {faBolt} from '@fortawesome/free-solid-svg-icons'
import HelpBox from '@components/help/HelpBox'
import CollapsibleSection from '@components/CollapsibleSection'
import FilterToggleButtons from './FilterToggleButtons'
import FilterCropUsage from './FilterCropUsage'

import RenderlessFilterComponent from '@components/filter/RenderlessFilterComponent'

export default {
  components: {
    RenderlessFilterComponent,
    HelpBox,
    FilterToggleButtons,
    CollapsibleSection,
    FilterCropUsage
  },
  data () {
    return {
      cultivationSelectedHover: false,
      cultivationfilterCollapsed: false,
      cropUsagefilterCollapsed: false
    }
  },
  computed: {
    icons () {
      return {
        bolt: faBolt
      }
    },
    ...mapState('fieldRecordSystem/cultivationOverview', [
      'cultivations',
      'filterRules',
      'selected',
      'visible'
    ]),
    ...mapGetters('fieldRecordSystem/cultivationOverview', [
      'cultivationData'
    ]),
    labelRuleCount () {
      return this.filterRules.filter(x => x.property === 'label').length
    },
    typeRuleCount () {
      return this.filterRules.filter(x => x.property === 'type').length
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/cultivationOverview', [
      'setSelected',
      'clearSelected',
      'setVisibleCultivations',
      'clearFilter'
    ]),
    calculateInvisible (acceptedDataIds) {
      return this.cultivationData.filter(cultivation => this.selected.includes(cultivation.id))
          .filter(cultivation => !acceptedDataIds.includes(cultivation.id))
          .map(cultivation => cultivation.label)
          .join(', ')
    },
    invisibleCount (acceptedDataIds) {
      return this.selected.filter(id => !acceptedDataIds.includes(id)).length
    }
  }
}
</script>

<style lang="scss" scoped>
.renderless {
  margin: 0 0 20px 16px;
}
.cultivation-card{
  margin-bottom: 5px;
}
.collapsible-hint{
  margin: 0 0.5em;
  color: #c6c3c3;
  font-size: 0.8em;
  display: inline;
}
.help-box{
  margin-bottom: 5px;
}
.link{
  margin-right: 30px;
}
</style>
